const HeroSocials = () => {
    return (
        <>
            <div className="hero-social-elements d-none d-xxl-block">
                <div className="flp-text">
                    <p>Follow Us</p>
                </div>
                <div className="long-arrow"></div>
                <div className="social-link">
                    {/* <a href="https://twitter.com/eCavTech">
                        <i className="fab fa-twitter"></i>
                    </a> */}
                    <a href="https://www.linkedin.com/company/ecavtechnologies/">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </>
    );
};

export default HeroSocials;
